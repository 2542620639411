import React from "react";
import AnimatedLogoBg from "./AnimatedLogoBg";

function App() {
  return (
    <div className="App">
      <AnimatedLogoBg />
    </div>
  );
}

export default App;
