import { useRef, useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { LineSegments, BufferGeometry, Float32BufferAttribute, LineBasicMaterial, Group, Color } from "three";
import { EffectComposer, Noise, Vignette } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import { noise } from "@chriscourses/perlin-noise";

const AnimatedTopo = ({ mousePosition }) => {
  const linesRef = useRef();
  const groupRef = useRef();

  useFrame(({ clock }) => {
    if (linesRef.current?.geometry) {
      const time = clock.getElapsedTime();
      const positions = linesRef.current.geometry.attributes.position?.array;
      const colors = new Float32Array(positions.length);

      if (positions) {
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];

          // Calcula la distancia del mouse
          const distance = Math.sqrt(
            (x - mousePosition.x * 100) ** 2 + (y - mousePosition.y * 100) ** 2
          );

          // Calcula la intensidad de la luz
          const intensity = Math.max(1 - distance / 50, 0);
          const smoothIntensity = Math.pow(intensity, 2.5);

          // Aplica la distorsión a la malla
          positions[i + 2] = noise(x * 0.2, y * 0.2 + time * 0.1, time * 0.2) * 15;

          // Aplica el color con más brillo donde pasa el mouse
          colors[i] = 0.1 + smoothIntensity * 0.9;
          colors[i + 1] = 0.1 + smoothIntensity * 0.9;
          colors[i + 2] = 0.1 + smoothIntensity * 0.9;
        }

        linesRef.current.geometry.setAttribute("color", new Float32BufferAttribute(colors, 3));
        linesRef.current.geometry.attributes.position.needsUpdate = true;
      }
    }

    if (groupRef.current) {
      groupRef.current.rotation.x = mousePosition.y * 0.2;
      groupRef.current.rotation.y = mousePosition.x * 0.2;
    }
  });

  useEffect(() => {
    const geometry = new BufferGeometry();
    const size = 150; // Asegura que cubra toda la pantalla
    const step = 1;
    const vertices = [];

    for (let y = -size; y <= size; y += step) {
      for (let x = -size; x <= size; x += step) {
        vertices.push(x, y, 0, x + step, y, 0);
      }
    }

    geometry.setAttribute("position", new Float32BufferAttribute(vertices, 3));
    if (linesRef.current) {
      linesRef.current.geometry = geometry;
    }
  }, []);
  
  return (
    <group ref={groupRef}>
      <lineSegments ref={linesRef}>
		<lineBasicMaterial attach="material" color="grey" linewidth={1} transparent opacity={0.3} />
      </lineSegments>
    </group>
  );
};

const TypewriterText = ({ text, delay, cycleTime }) => {
  const [displayedText, setDisplayedText] = useState("");
  
  useEffect(() => {
    let i = 0;
    let interval;
    
    const cycle = () => {
      setDisplayedText("");
      i = 0;
      interval = setInterval(() => {
        setDisplayedText(text.slice(0, i));
        i++;
        if (i > text.length) {
          clearInterval(interval);
          setTimeout(() => {
            setDisplayedText("");
            setTimeout(cycle, cycleTime);
          }, 5000);
        }
      }, delay);
    };
    
    cycle();
    return () => clearInterval(interval);
  }, [text, delay, cycleTime]);
  
  return <p className="font-mono text-white text-center text-sm">{displayedText}</p>;
};

export default function AnimatedLogoBg() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1,
      });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div className="w-screen h-screen bg-black flex items-center justify-center relative overflow-hidden">
     <Canvas 
  camera={{ position: [0, -60, 100], fov: 40 }}
  style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: -1, pointerEvents: 'none' }}
>

        <AnimatedTopo mousePosition={mousePosition} />
        <EffectComposer>
          <Noise blendFunction={BlendFunction.OVERLAY} premultiply opacity={0.2} />
          <Vignette eskil={false} offset={0.2} darkness={1} />
        </EffectComposer>
      </Canvas>
<div 
  className="logo-container"
  style={{
    zIndex: 50, // Asegura que esté por encima de la animación
    pointerEvents: "none", // Evita que interfiera con eventos del mouse
  }}
>
  <img 
    src="https://remostudio.com/logo.png" 
    alt="Logo" 
    className="w-[150px] md:w-[180px] lg:w-[220px]" 
    style={{
      maxWidth: "150px", // El logo nunca será más grande que el 25% de la pantalla
      maxHeight: "auto"
    }}
  />
</div>
      <div className="text-animated">
        <TypewriterText text="we are preparing something new" delay={50} cycleTime={5000} />
        <TypewriterText text="hello@remostudio.com" delay={80} cycleTime={5000} />
      </div>
    </div>
  );
}
